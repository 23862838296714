import logo from './logo.svg';
import './App.css';
import Data from './components/data';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import {Provider} from 'react-redux'
import { Button, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <div className = "InputData">
        <Data/>

      </div>
    </div>
  );
}

export default App;
