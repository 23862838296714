import React,{useState, useRef,PureComponent, useEffect, useContext} from 'react'
import './data.css'
// import { Button } from '@progress/kendo-react-buttons'
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet,Font, Image, pdf, usePDF } from '@react-pdf/renderer';
import { Button, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Img from './pechat.png'
import MaskedFormControl from 'react-bootstrap-maskedinput'
import InputMask from 'react-input-mask';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'


Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
  });

Font.register({
family: "Roboto-Medium",
src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    body: {
      padding: 10
    },
    table: { 
      display: "table", 
      width: "95%", 
      borderStyle: "solid", 
      borderColor: 'black',
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 ,
      marginLeft:15
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    }, 
    tableColHeader: { 
      width: "20%", 
      borderStyle: "solid", 
      borderColor: 'black',
      borderBottomColor: 'black',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    
    },   
    tableCol: { 
      width: "20%", 
      borderStyle: "solid", 
      borderColor: 'black',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCellHeader: {
    fontFamily: 'Roboto',
      margin: "auto", 
      margin: 5, 
      fontSize: 12,
      fontWeight: 500
    },  
    tableCell: { 
    fontFamily: 'Roboto',

      margin: "auto", 
      margin: 5, 
      fontSize: 10 
    }
  });
  
  
  var formData = new FormData();



function Data() {

    const [doors, setDoors] = useState([])
    const [productName,setProductName] = useState("Двери скрытого монтажа, короб алюминий, торец алюминий")
    const [height, setHeight] = useState()
    const [width, setWidth] = useState()
    const [volume, setVolume] = useState()
    const [opening, setOpening] = useState('Наружное правое')
    const [alum, setAlum] = useState('Серебро')
    const [dopPetlya, setDopPetlya] = useState('')


    const [doorHandle, setDoorHandle] = useState('FORME 514-RIC ATX № 00000010964')

 
    
    const [amount, setAmount] = useState(1)

    const [price, setPrice] = useState(31000)
    const [totalPrice, setTotalPrice] = useState(0)

    const [falum, setFalum] = useState()


    const [count, setCount] = useState(1);

    const [details, setDetails] = useState('')
    const [clientInfo, setClientInfo] = useState('')

    const [check, setCheck] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [check3, setCheck3] = useState(false)
    const [check4, setCheck4] = useState(false)



    const [customerName, setCustomerName] = useState("")
    const [customerAddress, setCustomerAddress] = useState("")
    const [customerContacts, setCustomerContacts] = useState("")
    const [agreementNumber, setAgreementNumber] = useState("")



    const [petli , setPetli] = useState(0)
    const [suhar, setSuhar] = useState(0)
    const [uplot, setUplot] = useState(0)
    const [alumKorob, setAlumKorob] = useState(0)
    const [mech, setMech] = useState(0)
    const [alumTor, setAlumTor] = useState(0)



    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
	};


    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '.' + mm + '.' + yyyy;




   
    useEffect(()=>{

    })


    // useEffect(()=>{

        const addOneDoor = (count,name, height, width,  opening, aluminiy, price, volume, details, doorHandle , amount) => {
            let newDoor = {
                count:count,
                name:name,
                // height:height,
                height: name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || name == 'Двери скрытого монтажа, короб алюминия, торец дерево' || name == 'Доборная планка в комплекте с наличником' ? height : null,
                width: name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || name == 'Двери скрытого монтажа, короб алюминия, торец дерево' || name == 'Доборная планка в комплекте с наличником' ? width : null,
                size: name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || name == 'Двери скрытого монтажа, короб алюминия, торец дерево' || name == 'Доборная планка в комплекте с наличником' ? [height,width].join('x') : null,
                opening: name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || name == 'Двери скрытого монтажа, короб алюминия, торец дерево'? opening : null,
                aluminiy: name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || name == 'Двери скрытого монтажа, короб алюминия, торец дерево'? aluminiy : null,
                
               

                price:  
                
                
                name == "Ручки"?
                doorHandle =="FORME 514-RIC ATX № 00000010964"?25200:
                doorHandle =="FORME 295-RIC ATX № 00000010132"?37400:
                doorHandle =="FORME 301-RIC MN/WENGE № 00000010133"?40400:
                doorHandle =="FORME 541-RIC  M.CHR № 00000010963"?24000:
                doorHandle =="FORME 299-RIC MCHR/BLACK № 00000010151"?40000:
                doorHandle =="FORME 514-RIC WHT № 00000010413"?24000:
                doorHandle =="FORME A213-RFQ PD № 00000008514"?25100:
                doorHandle =="FORME A213-RFQ ATX № 00000008513"?25100:
                doorHandle =="FORME A213-RFQ BN № 00000008519"?24000:
                doorHandle =="FORME A204-RFQ ATX № 00000008522"?20600:
                doorHandle =="FORME A422-RFQ PD № 00000008521"?26200:
                doorHandle =="FORME A254-RFQ ATX № 0000009063"?21000:
                doorHandle =="FORME A422-RFQ ATX № 00000010129"?26200:
                doorHandle =="FORME A422-RFQ MN № 00000008520"?25100:
                doorHandle =="FORME A508-RFQ PD № 00000008527"?24300:
                doorHandle =="FORME A508-RFQ MN № 00000008526"?23300:
                doorHandle =="FORME A201-RFQ PD № 00000008921"?27700:
                doorHandle =="FORME A201-RFQ MN № 00000008430"?26700:null
               
                // doorHandle =="FORME A201-RFQ MN № 00000008430"?26700:null






                :

                name != "Выпадающий порог" && name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || name == 'Двери скрытого монтажа, короб алюминия, торец дерево'?

                Math.round(price*((width/1000) * (height/1000)))
                :
                name == "Доборная планка в комплекте с наличником"?
                Math.round(48000*((width/1000) * (height/1000))):
                null
                
                
                ,

                
                amount:amount,
                
                // price: volume * price / 100000,
                // price: Math.round(price*((width/1000) * (height/1000))),
                volume: volume,
                details: productName =="Ручки" || productName =="Выпадающий порог"? amount + " штук" : details ,

                // details:details,
                doorHandle:doorHandle,
                
            }
            

            if (newDoor.name =="Ручки" || newDoor.name =="Выпадающий порог" ){

                setDoors(
                    [...doors, newDoor]
                )

            }


            else{

                for (let i = 0; i < amount; i++) {
                    setTimeout(() => {
                        
                        setDoors(doors => {
                            const newDoors = [...doors, newDoor];
                            return newDoors;
                        });
                    }, 0 );
                }

            }

            
            if(newDoor.name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || newDoor.name == 'Двери скрытого монтажа, короб алюминия, торец дерево' || newDoor.name == 'Доборная планка в комплекте с наличником' ){
            
            setTotalPrice(totalPrice + newDoor.price*amount)
            
            }
                // setDoors([  
               
                //     ...doors,
                //     newDoor
                  
                   
                //   ])

        
        }
    


    // },[])

    function currencyFormat(num) {
        return '' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
     }

    const doors1 = doors.filter(door => door.name == "Выпадающий порог" || door.name == "Ручки" );

     
    // useEffect(()=>{
    //     console.log(doors1)
    // },[doors])


    const MyDoc = (
        
        <Document>
                <Page style={styles.body}>

                <View style={{alignContent:'flex-end', textAlign:'right'}}>
                    <Text style ={{fontFamily:'Roboto', fontSize:12, fontColor:'black'}}>Приложение №1 к договору 1</Text>
                </View>
                <View style={{alignContent:'flex-end', textAlign:'center',marginTop:20, marginBottom:10}}>
                    <Text style ={{fontFamily:'Roboto-Medium'}}>Спецификация № {agreementNumber} от {today}</Text>
                </View>
                
                
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={{ width: "5%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader}>№ П/П</Text> 
                    </View> 
                    <View style={{ width: "35%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader}>Наименование товара</Text> 
                    </View> 
                    <View style={{ width: "15%", 
                    borderStyle: "solid", 
                    borderColor: '#bfbfbf',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader}>Размеры полотен (мм)</Text> 
                    </View>
                    <View style={{width: "15%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader}>Открывание</Text> 
                    </View>  
                    <View style={{width: "15%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader}>Алюминий</Text> 
                    </View> 
                    <View style={{width: "15%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader}>Стоимость (тенге)</Text> 
                    </View> 
                    </View>


                    
    
                    {doors.map(((item,index) => (
    
                        item.name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || item.name == 'Двери скрытого монтажа, короб алюминия, торец дерево' || item.name == 'Доборная планка в комплекте с наличником' ?
    
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{index+1}</Text> 
                        </View> 

                        {item.name !="Ручки"?
                        <View style={{width: "35%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name}</Text> 
                        </View> 
                        :
                        <View style={{width: "35%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name} {item.doorHandle}</Text> 
                        </View> 

                        }
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}>{item.size}</Text> 
                        </View>
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderBottomColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0}}> 
                            <Text style={styles.tableCellHeader}>{item.opening}</Text> 
                        </View> 
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.aluminiy}</Text> 
                        </View> 
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{currencyFormat(item.price)}</Text> 
                        </View> 
                        </View> 
                        :null
                        
    
                    )))}

                    

                            
                </View>
                <View style={{alignContent:'flex-end', textAlign:'right', marginRight:15, marginTop:5}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Общая стоимость заказа: {currencyFormat(totalPrice)}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Предоплата: {currencyFormat(Math.round(totalPrice*0.7))}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Остаток: {currencyFormat(Math.round(totalPrice-totalPrice*0.7))}</Text>
                </View>

                <View style={{alignContent:'flex-end', textAlign:'left', marginLeft:15}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>1. Товар обладает качеством и ассортиментом, соответсвующим требованиям.</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>2. Срок изготовления заказа 7 календарных дней с момента поступления аванса на счет Исполнителя.</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>3. Прием заказа осуществляется по адресу: Отрарская 9, с 9.00 до 13.00.</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>4. Настоящая спецификация составлена на русском языке в двух экземплярах, имеющих равную юридическую силу, по одному экземпляру для каждой из Сторон и является неотъемлемой частью указанного выше Договора между Сторонами. </Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}></Text>

                </View>

                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal', textDecoration:'underline'}}>Заказчик:</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal',textDecoration:'underline'}}>Исполнитель:</Text>
                        </View>
                    </View>
                </View>


                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            {check?
                            <Image style={{width:120, height:120, position:'absolute', bottom:-38}} src={Img}/>
                            :
                            null
                            }
                            
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin' }}>____________________________/Дюсюнов Д.К.</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}>____________________________/____________________________/</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>

                        </View>
                    </View>
                </View>
                </Page>
                </Document>
       
     
        )

    const MyDoc2 = (
    
        <Document>
                <Page style={styles.body}>

                

                <View style={{alignContent:'flex-end', textAlign:'center',marginTop:20, marginBottom:20}}>
                    <Text style ={{fontFamily:'Roboto-Medium'}}>Акт № 1-{agreementNumber} от {today}</Text>
                    <View style={{textAlign:'justify', marginRight:20, marginLeft:15, marginTop:15}}>
                    <Text style={{fontFamily:'Roboto', fontSize:10}}>Товарищество с ограниченной ответственностью «KEPLER-4228», в лице директора Дюсюнов Д.К., действующей на основании устава, именуемое в дальнейшем Заказчик, с одной стороны и «___________________», именуемое в дальнейшем Исполнитель, с другой стороны (в дальнейшем вместе именуемые «Стороны» и по отдельности «Сторона»), составили настоящий Акт о нижеследующем:
Исполнитель передает, а Заказчик принимает Товар следующего ассортимента и количества:
                    </Text>
                    
                    </View>
                </View>
                
                
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={{ width: "5%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>№ П/П</Text> 
                    </View> 
                    <View style={{ width: "30%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Наименование товара</Text> 
                    </View> 
                    <View style={{ width: "20%", 
                    borderStyle: "solid", 
                    borderColor: '#bfbfbf',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Размеры полотен (мм)</Text> 
                    </View> 
                    <View style={{width: "15%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Открывание</Text> 
                    </View> 
                    <View style={{width: "15%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Алюминий</Text> 
                    </View> 
                    <View style={{width: "15%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Примечание</Text> 
                    </View> 
                    </View>
    
                    {doors.map(((item,index) => (
    
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{index+1}</Text> 
                        </View> 
                        {item.name !="Ручки"?
                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name}</Text> 
                        </View> 
                        :
                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name} {item.doorHandle}</Text> 
                        </View> 

                        }
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}>{item.size}</Text> 
                        </View>
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.opening}</Text> 
                        </View> 
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.aluminiy}</Text> 
                        </View> 

                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.details}</Text> 
                        </View> 
                        </View> 
                        
    
                    )))}


                        {/* Сухари */}
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors.length+1}</Text> 
                        </View> 
                        
                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Сухари</Text> 
                        </View> 
                        
                       
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 

                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{suhar} штук</Text> 
                        </View> 
                        </View> 

                        {/* Петли */}
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors.length+2}</Text> 
                        </View> 
                        
                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Петли</Text> 
                        </View> 
                        
                       
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 

                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{petli} штук</Text> 
                        </View> 
                        </View> 

                        {/* Уплотнитель */}

                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors.length+3}</Text> 
                        </View> 
                        
                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Уплотнитель</Text> 
                        </View> 
                        
                       
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 

                        <View style={{width: "15%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{uplot} метров</Text> 
                        </View> 
                        </View> 


                    

                    

                            
                </View>
                {/* <View style={{alignContent:'flex-end', textAlign:'right', marginRight:15, marginTop:5}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Общая стоимость заказа:{totalPrice}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Предоплата:{Math.round(totalPrice*0.7)}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Остаток:{Math.round(totalPrice-totalPrice*0.7)}</Text>
                </View> */}

                <View style={{alignContent:'flex-end', textAlign:'left', marginLeft:15,marginRight:10, marginTop:20}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>1. Принятый Заказчиком товар обладает качеством и ассортиментом, соответствующим требованиям Договора. Товар поставлен в установленные в Договоре сроки. Исполнитель не имеет никаких претензий к принятому им товару.
</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>2. Настоящий Акт составлен на русском языке в двух экземплярах, имеющих равную юридическую силу, по одному экземпляру для каждой из Сторон и является неотъемлемой частью указанного выше Договора между Сторонами.
</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>3. Адрес заказчика: {customerAddress}, {customerContacts} {customerName} </Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}></Text>

                </View>

                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal', textDecoration:'underline'}}>Заказчик:</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal',textDecoration:'underline'}}>Исполнитель:</Text>
                        </View>
                    </View>
                </View>


                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            {check?
                            <Image style={{width:120, height:120, position:'absolute', bottom:-38}} src={Img}/>
                            :
                            null
                            }
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin' }}>____________________________/Дюсюнов Д.К.</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}>____________________________/____________________________/</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>

                        </View>
                    </View>
                </View>

                <View style ={{marginLeft:15, marginTop:100}}>
                    <View style={{width:'50%'}}>
                        <View>
                            
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}><Text style={{fontFamily:'Roboto-Medium', }}>Товар принял:</Text>______________________/Мукашев </Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>A.М</Text>

                        </View>
                    </View>

                </View>
                </Page>
                </Document>
        
        
        )


    const MyDoc3 = (

        <Document>
                <Page style={styles.body}>

                

                <View style={{alignContent:'flex-end', textAlign:'center',marginTop:20, marginBottom:20}}>
                    <Text style ={{fontFamily:'Roboto-Medium'}}>Акт № 2-{agreementNumber} от {today}</Text>
                    <View style={{textAlign:'justify', marginRight:20, marginLeft:15, marginTop:15}}>
                    <Text style={{fontFamily:'Roboto', fontSize:10}}>Товарищество с ограниченной ответственностью «KEPLER-4228», в лице директора Дюсюнов Д.К., действующей на основании устава, именуемое в дальнейшем Заказчик, с одной стороны и «___________________», именуемое в дальнейшем Исполнитель, с другой стороны (в дальнейшем вместе именуемые «Стороны» и по отдельности «Сторона»), составили настоящий Акт о нижеследующем:
Исполнитель передает, а Заказчик принимает Товар следующего ассортимента и количества:
                    </Text>
                    
                    </View>
                </View>
                
                
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={{ width: "5%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>№ П/П</Text> 
                    </View> 
                    <View style={{ width: "45%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Наименование товара</Text> 
                    </View> 
                    <View style={{ width: "20%", 
                    borderStyle: "solid", 
                    borderColor: '#bfbfbf',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Количество</Text> 
                    </View> 
                    
                    <View style={{width: "30%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Примечание</Text> 
                    </View> 
                    </View>
    
                    {doors1.map(((item,index) => (


                        item.name == "Ручки" || item.name == "Выпадающий порог" ?
    
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{index+1}</Text> 
                        </View> 
                        {item.name !="Ручки"?
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name}</Text> 
                        </View> 
                        :
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name} {item.doorHandle}</Text> 
                        </View> 

                        }
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}>{item.details}</Text> 
                        </View>
                        
                        

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}> </Text> 
                        </View> 
                        </View> 
                        :
                        null
    
                    )))}

                    {/* Сухари */}
                    <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors1.length+1}</Text> 
                        </View> 
                        
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Сухари</Text> 
                        </View> 
                        
                       
                       
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{suhar} штук</Text> 
                        </View> 

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        </View> 

                        {/* Петли */}
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors1.length+2}</Text> 
                        </View> 
                        
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Петли AGB Eclipse 3.2</Text> 
                        </View> 
                        
                       
                        
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{petli} штук</Text> 
                        </View> 

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        </View> 

                        {/* Уплотнитель */}

                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors1.length+3}</Text> 
                        </View> 
                        
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Уплотнитель</Text> 
                        </View> 
                        
                       
                        
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{uplot} метров</Text> 
                        </View> 

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        </View> 


                        {/* Алюминиевый короб */}

                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors1.length+4}</Text> 
                        </View> 
                        
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Алюминиевый короб</Text> 
                        </View> 
                        
                       
                        
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{alumKorob} метров </Text> 
                        </View> 

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        </View> 


                        {/* Механизм */}

                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{doors1.length+5}</Text> 
                        </View> 
                        
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Механизм</Text> 
                        </View> 
                        
                       
                        
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{mech} штук </Text> 
                        </View> 

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        </View> 

                    

                            
                </View>
                {/* <View style={{alignContent:'flex-end', textAlign:'right', marginRight:15, marginTop:5}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Общая стоимость заказа:{totalPrice}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Предоплата:{Math.round(totalPrice*0.7)}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Остаток:{Math.round(totalPrice-totalPrice*0.7)}</Text>
                </View> */}

                <View style={{alignContent:'flex-end', textAlign:'left', marginLeft:15,marginRight:10, marginTop:20}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>1. Принятый Заказчиком товар обладает качеством и ассортиментом, соответствующим требованиям Договора. Товар поставлен в установленные в Договоре сроки. Исполнитель не имеет никаких претензий к принятому им товару.
</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>2. Настоящий Акт составлен на русском языке в двух экземплярах, имеющих равную юридическую силу, по одному экземпляру для каждой из Сторон и является неотъемлемой частью указанного выше Договора между Сторонами.
</Text>
                        {/* <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>3. Адрес заказчика: {customerAddress}, {customerContacts} {customerName} </Text> */}
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}></Text>

                </View>

                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal', textDecoration:'underline'}}>Заказчик:</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal',textDecoration:'underline'}}>Исполнитель:</Text>
                        </View>
                    </View>
                </View>


                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            {check?
                            <Image style={{width:120, height:120, position:'absolute', bottom:-38}} src={Img}/>
                            :
                            null
                            }
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin' }}>____________________________/Дюсюнов Д.К.</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}>____________________________/____________________________/</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>

                        </View>
                    </View>
                </View>

                {/* <View style ={{marginLeft:15, marginTop:100}}>
                    <View style={{width:'50%'}}>
                        <View>
                            
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}><Text style={{fontFamily:'Roboto-Medium', }}>Товар принял:</Text>______________________/Мукашев </Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>A.М</Text>

                        </View>
                    </View>

                </View> */}
                </Page>
                </Document>
        
        
        )


    const MyDoc4 = (

        <Document>
                <Page style={styles.body}>

                

                <View style={{alignContent:'flex-end', textAlign:'center',marginTop:20, marginBottom:20}}>
                    <Text style ={{fontFamily:'Roboto-Medium'}}>Акт № 3-{agreementNumber} от {today}</Text>
                    <View style={{textAlign:'justify', marginRight:20, marginLeft:15, marginTop:15}}>
                    <Text style={{fontFamily:'Roboto', fontSize:10}}>Товарищество с ограниченной ответственностью «KEPLER-4228», в лице директора Дюсюнов Д.К., действующей на основании устава, именуемое в дальнейшем Заказчик, с одной стороны и «___________________», именуемое в дальнейшем Исполнитель, с другой стороны (в дальнейшем вместе именуемые «Стороны» и по отдельности «Сторона»), составили настоящий Акт о нижеследующем:
Исполнитель передает, а Заказчик принимает Товар следующего ассортимента и количества:
                    </Text>
                    
                    </View>
                </View>
                
                
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={{ width: "5%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>№ П/П</Text> 
                    </View> 
                    <View style={{ width: "45%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Наименование товара</Text> 
                    </View> 
                    <View style={{ width: "20%", 
                    borderStyle: "solid", 
                    borderColor: '#bfbfbf',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Количество</Text> 
                    </View> 
                    
                    <View style={{width: "30%", 
                    borderStyle: "solid", 
                    borderColor: 'black',
                    borderBottomColor: 'black',
                    borderWidth: 1, 
                    borderLeftWidth: 0, 
                    borderTopWidth: 0}}> 
                        <Text style={styles.tableCellHeader,{fontFamily:'Roboto', fontSize:12}}>Примечание</Text> 
                    </View> 
                    </View>
    
                    {/* {doors.map(((item,index) => (


                        item.name == "" || item.name == "" ?
    
                        <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{index+1}</Text> 
                        </View> 
                        {item.name !="Ручки"?
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name}</Text> 
                        </View> 
                        :
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{item.name} {item.doorHandle}</Text> 
                        </View> 

                        }
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}>
                            <Text style={styles.tableCell}>{item.details}</Text> 
                        </View>
                        
                        

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}> </Text> 
                        </View> 
                        </View> 
                        :
                        null
    
                    )))} */}

                    {/* Алюминиевые торцы */}
                    <View style={styles.tableRow}> 
                        <View style={{width: "5%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            {/* <Text style={styles.tableCell}>{doors.length+1}</Text>  */}
                            <Text style={styles.tableCell}>1</Text> 

                        </View> 
                        
                        <View style={{width: "45%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>Алюминиевые торцы</Text> 
                        </View> 
                        
                        
                        
                        <View style={{width: "20%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}>{alumTor} штук</Text> 
                        </View> 

                        <View style={{width: "30%", 
                        borderStyle: "solid", 
                        borderColor: 'black',
                        borderWidth: 1, 
                        borderLeftWidth: 0, 
                        borderTopWidth: 0 }}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        </View> 

                        

                    

                            
                </View>
                {/* <View style={{alignContent:'flex-end', textAlign:'right', marginRight:15, marginTop:5}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Общая стоимость заказа:{totalPrice}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Предоплата:{Math.round(totalPrice*0.7)}</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>Остаток:{Math.round(totalPrice-totalPrice*0.7)}</Text>
                </View> */}

                <View style={{alignContent:'flex-end', textAlign:'left', marginLeft:15,marginRight:10, marginTop:20}}>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>1. Принятый Заказчиком товар обладает качеством и ассортиментом, соответствующим требованиям Договора. Товар поставлен в установленные в Договоре сроки. Исполнитель не имеет никаких претензий к принятому им товару.
</Text>
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>2. Настоящий Акт составлен на русском языке в двух экземплярах, имеющих равную юридическую силу, по одному экземпляру для каждой из Сторон и является неотъемлемой частью указанного выше Договора между Сторонами.
</Text>
                        {/* <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}>3. Адрес заказчика: {customerAddress}, {customerContacts} {customerName} </Text> */}
                        <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'normal'}}></Text>

                </View>

                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal', textDecoration:'underline'}}>Заказчик:</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal',textDecoration:'underline'}}>Исполнитель:</Text>
                        </View>
                    </View>
                </View>


                <View style={{flexDirection:'row', marginLeft:15, marginTop:50}}>
                    <View style={{width:'50%'}} >
                        <View>
                            {check?
                            <Image style={{width:120, height:120, position:'absolute', bottom:-38}} src={Img}/>
                            :
                            null
                            }
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin' }}>____________________________/Дюсюнов Д.К.</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>
                        </View>
                    </View>

                    <View style={{width:'50%'}}>
                        <View>
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}>____________________________/____________________________/</Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>М.П</Text>

                        </View>
                    </View>
                </View>

                {/* <View style ={{marginLeft:15, marginTop:100}}>
                    <View style={{width:'50%'}}>
                        <View>
                            
                            <Text style ={{fontFamily:'Roboto',fontSize:10, fontWeight:'thin'}}><Text style={{fontFamily:'Roboto-Medium', }}>Товар принял:</Text>______________________/Мукашев </Text>
                            <Text style = {{fontFamily:'Roboto-Medium',fontSize:10, fontWeight:'normal'}}>A.М</Text>

                        </View>
                    </View>

                </View> */}
                </Page>
                </Document>
        
        
        )
     


    const [instance, updateInstance] = usePDF({ document: MyDoc });
    const [instance2, updateInstance2] = usePDF({ document: MyDoc2 });
    const [instance3, updateInstance3] = usePDF({ document: MyDoc3 });
    const [instance4, updateInstance4] = usePDF({ document: MyDoc4 });




    useEffect(()=>{
        
        updateInstance({document: MyDoc})
        updateInstance2({document: MyDoc2})
        updateInstance3({document: MyDoc3})
        updateInstance4({document: MyDoc4})



    },[doors, check, check2, check3, check4])

    const [show, setShow] = useState(false);

    return (
        
        <div style={{width:'90%'}}>

            
            {/* {MyDoc} */}
            <div style={{marginLeft:'15%', marginTop:20}}>

                <div>
                    <h style={{fontSize:20}}>Информация о заказчике</h>
                    <div style={{flexDirection:'row', display:'table', borderSpacing:10}}>

                        <div style={{ width:200, marginRight:10, display: 'table-cell'}}>
                            <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>ФИО заказчика</Form.Label>
                                <Form.Control onChange={(e) =>{
                                    setCustomerName(e.target.value)
                                    // console.log(details)

                                }}  size ="sm" type="email" placeholder="" value={customerName} />
                            </Form.Group>
                            </Form>
                        </div>

                        <div style={{ width:200, marginRight:10,display: 'table-cell'}}>
                            <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>Адрес заказчика</Form.Label>
                                <Form.Control onChange={(e) =>{
                                    setCustomerAddress(e.target.value)
                                    // console.log(details)

                                }}  size ="sm" type="email" placeholder="" value={customerAddress} />
                            </Form.Group>
                            </Form>
                        </div>

                        <div style={{ width:200, marginRight:10,display: 'table-cell'}}>
                            <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>Контакты заказчика</Form.Label>
                                {/* <Form.Control   size ="sm" type="email" placeholder="" value={customerContacts} /> */}
                                <MaskedFormControl onChange={(e) =>{
                                    setCustomerContacts(e.target.value)
                                    // console.log(details)

                                }}
                                 style ={{height:32, fontSize:14}} type='text' name='phoneNumber' value={customerContacts} mask='+7 (111) 111 11 11'  />
                            </Form.Group>
                            </Form>
                        </div>

                        <div style={{ width:200, marginRight:10,display: 'table-cell'}}>
                            <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>№</Form.Label>
                                <Form.Control onChange={(e) =>{
                                    setAgreementNumber(e.target.value)
                                    // console.log(details)

                                }}  size ="sm" type="email" placeholder="" value={agreementNumber} />
                            </Form.Group>
                            </Form>
                        </div>

                    </div>

                </div>

                <div style ={{marginTop:20}}>
                    <h style={{fontSize:20}}>Информация о товаре</h>
                    
                    


                    <div style={{marginTop:10}}>
                        <div style ={{}}>
                        <div style={{display:'inline-flex'}}>
                            <div style={{marginRigt:10, display:'table', borderSpacing:10}}>
                                
                                <div style={{marginRight:10, display:'table-cell'}}>
                                {/* <h>Наименование товара</h> */}
                                <Form.Label>Наименование товара</Form.Label>
                                <Form.Select size="sm" onChange={
                                        (e)=>{
                                            setProductName(e.target.value)
                                            console.log(productName)
                                        }
                                    }>
                                        <option value="Двери скрытого монтажа, короб алюминий, торец алюминий">Двери скрытого монтажа, короб алюминий, торец алюминий </option>
                                        <option value="Двери скрытого монтажа, короб алюминия, торец дерево">Двери скрытого монтажа, короб алюминия, торец дерево</option>
                                        <option value="Доборная планка в комплекте с наличником">Доборная планка в комплекте с наличником </option>
                                        <option value="Выпадающий порог">Выпадающий порог</option>
                                        <option value="Ручки">Ручки </option>
                                </Form.Select>
                                </div>

                                <div style={{ width:340, marginRight:10,display:'table-cell'}}>
                                    <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Примечание</Form.Label>
                                        <Form.Control onChange={(e) =>{
                                            setDetails(e.target.value)
                                            console.log(details)

                                        }}  size ="sm" type="email" placeholder="" value={details} />
                                    </Form.Group>
                                    </Form>
                                </div>
                            </div>



                        </div>

                    <div style={{display:'inline-flex', marginTop:10}}>

                        {/* Опции для дверей и планки */}

                        {productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево' || productName == 'Доборная планка в комплекте с наличником' ? 
                        
                        <div style ={{display:'table', borderSpacing:10}}>


                            <div style ={{width:150, marginRight:10,  display:'table-cell'}}>
                                <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Высота</Form.Label>
                                    <Form.Control onChange={(e) =>{
                                        setHeight(e.target.value)
                                        
                                        console.log(height)
                                        setVolume(height*width)
                                    }}  value={height} size ="sm" type="email" placeholder="" />
                                </Form.Group>
                                </Form>
                            </div>


                            <div style ={{width:150, marginRight:10, display:'table-cell'}}>
                                <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Ширина</Form.Label>
                                    <Form.Control onChange={(e) =>{
                                        setWidth(e.target.value)
                                        console.log(width)
                                        setVolume(height*width)

                                    }} value ={width}  size ="sm" type="email" placeholder="" />
                                </Form.Group>
                                </Form>
                            </div>

                            


                            {productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево' ? 

                            <div>
                            <div style ={{width:170, marginRight:10,  display:'table-cell'}}>
                                <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Открывание</Form.Label>
                                    <Form.Select size="sm" onChange={
                                        (e)=>{
                                            setOpening(e.target.value)
                                            console.log(opening)
                                        }
                                    }>
                                            <option value="Наружное правое">Наружное правое</option>
                                            <option value="Наружное левое">Наружное левое</option>
                                            <option value="Внутреннее правое ">Внутреннее правое </option>
                                            <option value="Внутреннее левое">Внутреннее левое</option>

                                            
                                    </Form.Select>
                                </Form.Group>
                                </Form>
                            </div>

                            <div style ={{width:150, marginRight:10,  display:'table-cell'}}>
                                <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Алюминий</Form.Label>
                                    <Form.Select size="sm" onChange={
                                        (e)=>{
                                            setAlum(e.target.value)
                                            console.log(alum)
                                        }
                                    }>
                                        <option value="Серебро ">Серебро </option>
                                        <option value="Чёрный">Чёрный</option>

                                                
                                    </Form.Select>
                                </Form.Group>
                                </Form>
                            </div>
                            </div>

                            :
                            null
                            }

                            


                        </div>
                        
                        :

                        // <div style={{width:150}}>
                        
                        // </div>
                        null

                        }


                        


                        
                        <div style={{display:'table', borderSpacing:10}}>

                        {productName == 'Ручки'? 
                            <div style={{display:'table-cell'}}>
                                <h>Ручки</h>
                                <div style = {{width:355, marginTop:9, marginRight:9}}>
                                    <Form.Select size="sm" onChange={
                                        (e)=>{
                                            setDoorHandle(e.target.value)
                                            console.log(doorHandle)
                                        }
                                    }>
                                            <option value="FORME 514-RIC ATX № 00000010964">FORME 514-RIC ATX № 00000010964</option>
                                            <option value="FORME 295-RIC ATX № 00000010132">FORME 295-RIC ATX № 00000010132</option>
                                            <option value="FORME 301-RIC MN/WENGE № 00000010133">FORME 301-RIC MN/WENGE № 00000010133</option>
                                            <option value="FORME 541-RIC  M.CHR № 00000010963">FORME 541-RIC  M.CHR № 00000010963</option>
                                            <option value="FORME 299-RIC MCHR/BLACK № 00000010151">FORME 299-RIC MCHR/BLACK № 00000010151</option>
                                            <option value="FORME 514-RIC WHT № 00000010413">FORME 514-RIC WHT № 00000010413</option>
                                            <option value="FORME A213-RFQ PD № 00000008514">FORME A213-RFQ PD № 00000008514</option>
                                            <option value="FORME A213-RFQ ATX № 00000008513">FFORME A213-RFQ ATX № 00000008513</option>

                                            <option value="FORME A213-RFQ BN № 00000008519">FORME A213-RFQ BN № 00000008519</option>
                                            <option value="FORME A204-RFQ ATX № 00000008522">FORME A204-RFQ ATX № 00000008522</option>
                                            <option value="FORME A422-RFQ PD № 00000008521">FORME A422-RFQ PD № 00000008521</option>
                                            <option value="FORME A254-RFQ ATX № 0000009063">FORME A254-RFQ ATX № 0000009063</option>
                                            <option value="FORME A422-RFQ ATX № 00000010129">FORME A422-RFQ ATX № 00000010129</option>
                                            <option value="FORME A422-RFQ MN № 00000008520">FORME A422-RFQ MN № 00000008520</option>
                                            <option value="FORME A508-RFQ PD № 00000008527">FORME A508-RFQ PD № 00000008527</option>
                                            <option value="FORME A508-RFQ MN № 00000008526">FORME A508-RFQ MN № 00000008526</option>

                                            <option value="FORME A201-RFQ PD № 00000008921">FORME A201-RFQ PD № 00000008921</option>
                                            <option value="FORME A201-RFQ MN № 00000008430">FORME A201-RFQ MN № 00000008430</option>


                                            {/* <option value="FORME A508-RFQ MN № 00000008526">FORME A508-RFQ MN № 00000008526</option> */}

                                            
                                    </Form.Select>
                                </div>
                            </div>
                        :
                        null
                        
                        }


                        {productName == 'Доборная планка в комплекте с наличником'?
                        
                        <div style ={{width:150,  marginTop:0}}>
                                <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Количество</Form.Label>
                                <div style={{display:'inline-flex'}}> 


                                    <Button onClick={()=>{
                                        setAmount(amount+1)
                                    }} style ={{height:30, width:30, marginRight:10,lineHeight:1, paddingRight:20}} variant="outline-secondary">+</Button>{' '}

                                    <Form.Control value={amount} style={{width:35,height:30, marginRight:10}} size="sm" type="text" placeholder="" />

                                    {amount ==1?
                                        <Button onClick={()=>{
                                            
                                        }} style ={{height:30, width:30,lineHeight:1, paddingRight:18}} variant="outline-secondary">-</Button>
                                        :
                                        <Button onClick={()=>{
                                            setAmount(amount-1)
                                        }} style ={{height:30, width:30,lineHeight:1, paddingRight:18}} variant="outline-secondary">-</Button>


                                    }

                                    </div>
                                </Form.Group>
                                </Form>
                            </div>

                            :

                            <div style ={{width:150,  marginTop:10}}>
                                <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Количество</Form.Label>
                                <div style={{display:'inline-flex'}}> 


                                    <Button onClick={()=>{
                                        setAmount(amount+1)
                                    }} style ={{height:30, width:30, marginRight:10,lineHeight:1, paddingRight:20}} variant="outline-secondary">+</Button>{' '}

                                    <Form.Control value={amount} style={{width:35,height:30, marginRight:10}} size="sm" type="text" placeholder="" />

                                    {amount ==1?
                                        <Button onClick={()=>{
                                            
                                        }} style ={{height:30, width:30,lineHeight:1, paddingRight:18}} variant="outline-secondary">-</Button>
                                        :
                                        <Button onClick={()=>{
                                            setAmount(amount-1)
                                        }} style ={{height:30, width:30,lineHeight:1, paddingRight:18}} variant="outline-secondary">-</Button>


                                    }

                                    </div>
                                </Form.Group>
                                </Form>
                            </div>
                    
                        }

                            



                    </div>
                            
                  

                    </div>

                        
                    </div>
                    </div>
                    
                </div>


                

                

                

            </div>


            <div style={{marginLeft:"56vw", display:'inline-flex', marginTop:10}}>

                    <div style={{marginRight:10}}>
                        {doors.length != 0 ?
                            <Button onClick={()=>{
                                console.log(doors[doors.length-1].price)

                                // if (doors[doors.length-1].name == "Ручки" || doors[doors.length-1].name == "Выпадающий порог" ) {
                                //     setTotalPrice(totalPrice-doors[doors.length-1].price*doors[doors.length-1].amount)
                                // }
                                // else{
                                //     setTotalPrice(totalPrice-doors[doors.length-1].price)

                                // }


                                if(doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминия, торец дерево' || doors[doors.length-1].name == "Доборная планка в комплекте с наличником"){
                                    setTotalPrice(totalPrice-doors[doors.length-1].price)

                                }
                                setDoors(doors.slice(0,-1))
                                setCount(count-1)


                                


                                doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                                setSuhar(suhar-2)
                                :
                                setSuhar(suhar)


                                doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                                doors[doors.length-1].height < 2300 ? setPetli(petli-2):
                                doors[doors.length-1].height < 2600 ? setPetli(petli-3):
                                doors[doors.length-1].height < 3000 ? setPetli (petli-4): setPetli(petli): setPetli(petli)


                                doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                                setUplot(uplot -Math.round((doors[doors.length-1].height/1000 *2.5 +doors[doors.length-1].width/1000)))
                                :
                                setUplot(uplot)


                                doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                                setAlumKorob(alumKorob -Math.round((doors[doors.length-1].height/1000 *2 +doors[doors.length-1].width/1000+ 0.3)))
                                :
                                setAlumKorob(alumKorob)


                                doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                                setMech(mech-1)
                                :
                                setMech(mech)


                                doors[doors.length-1].name == 'Двери скрытого монтажа, короб алюминий, торец алюминий' ?
                                setAlumTor(alumTor-1)
                                :
                                setAlumTor(alumTor)







                                console.log(doors)
                            }} variant="danger">
                                Удалить
                            </Button>
                            :
                            <Button onClick={()=>{
                            }} variant="danger">
                                Удалить
                            </Button>
                        }
                    </div>

                    <div style={{marginRight:10}}>
                        <Button onClick={()=>{

                            addOneDoor(count,productName,height,width,opening,alum,price, volume, details, doorHandle , amount)
                            setCount(count+1)

                            productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                            setSuhar(suhar+2*amount)
                            :
                            setSuhar(suhar)


                            productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                            height < 2300 ? setPetli(petli+2*amount):
                            height < 2600 ? setPetli(petli+3*amount):
                            height < 3000 ? setPetli (petli+4*amount): setPetli(petli): setPetli(petli)


                            productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                            setUplot(uplot +Math.round((height/1000 *2.5 +width/1000))*amount)
                            :
                            setUplot(uplot)


                            productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                            setAlumKorob(alumKorob +Math.round((height/1000 *2 +width/1000+ 0.3))*amount)
                            :
                            setAlumKorob(alumKorob)


                            productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' || productName == 'Двери скрытого монтажа, короб алюминия, торец дерево'?
                            setMech(mech+1*amount)
                            :
                            setMech(mech)


                            productName == 'Двери скрытого монтажа, короб алюминий, торец алюминий' ?
                            setAlumTor(alumTor+1*amount)
                            :
                            setAlumTor(alumTor)
                            
                        }}
                        variant="primary">Добавить </Button>{''}
                    </div>
            </div>

          


            <div style={{marginLeft:'15%'}}>       
                    <div id="divToPrint"  style={{ width:'70vw',height:'50%', marginTop:30}}>
                        <Table striped bordered hover  style ={{width:'100%'}} >
                            <thead >
                                <tr>
                                    <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>№ П/П</th>
                                    <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Наименование товара</th>
                                    <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Размеры полотен</th>
                                    <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Открывание</th>
                                    <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Алюминий</th>
                                    {/* <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Стоимость</th> */}
                                    {/* <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Объем</th> */}
                                    <th style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Примечание</th>

                                </tr>
                            </thead>
                            <tbody>
                        {doors.map((item, index) =>(
                            <tr >
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{index+1}</td>
                                {/* <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{count}</td> */}

                                {item.name == "Ручки"?
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.name} {item.doorHandle}</td>
                                :
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.name}</td>
                            
                                }

                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.size}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.opening}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.aluminiy}</td>
                                {/* <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{currencyFormat(item.price)}</td> */}
                                {/* <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.volume}</td> */}
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{item.details}</td>


                            </tr>
                        ))}

                        
                        
                            {/* <tr>
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{doors.length+1}</td>
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Сухари</td>
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>    
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                            <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{suhar} штук</td>                                

                            </tr>
                            <tr>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{doors.length+2}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Петли AGB Eclipse 3.2</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>    
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{petli} штук</td>                                

                            </tr>
                            <tr>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{doors.length+3}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Уплотнитель</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>    
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{uplot} метров</td>                                

                            </tr>
                            <tr>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{doors.length+4}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Алюминиевый короб</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>    
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{alumKorob} метров</td>                                

                            </tr>

                            <tr>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{doors.length+5}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Механизм</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>    
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{mech} штук</td>                                

                            </tr>

                            <tr>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{doors.length+6}</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>Алюминиевый торец</td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>    
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}></td>                                
                                <td style={{border:'1px solid black', fontWeight:'normal', fontSize:10}}>{alumTor} штук</td>                                

                            </tr> */}
                   
                            
                            </tbody>
                        </Table>
                        {/* <div style={{textAlign:'end'}}>
                            <p style={{fontSize:10}}>Общая стоимость заказа: {totalPrice}</p>
                            <p style={{fontSize:10}}>Предоплата: {Math.ceil(totalPrice*0.7)}</p>
                            <p style={{fontSize:10}}>Остаток: {totalPrice- Math.ceil(totalPrice*0.7)}</p>
                        </div> */}

                        <div style={{display:'inline-flex',alignItems:'end', marginBottom:20}}>
                            <div style={{justifyContent:'center', marginRight:10} } className="App"
                            onClick={()=>{
                                console.log('1234')
                            }}
                            
                            > 
                            {/* <PDFDownloadLink document={MyDoc} fileName="specification.pdf"
                            onClick={()=>{
                                console.log('1234')
                            }}
                            
                            >
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : ' Скачать Спецификаю PDF ')}
                            </PDFDownloadLink>
                            </div>

                            <div style={{justifyContent:'center', marginRight:10}} >
                            <PDFDownloadLink document={MyDoc2} fileName="act.pdf">
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Скачать Акт PDF')}
                            </PDFDownloadLink> */}

                            </div>

                            {/* <div>
                                <Form>
                                <Form.Check 
                                    onChange={()=>{
                                        setCheck(!check)
                                        console.log(check)
                                    }}
                                    type={'checkbox'}
                                    id={'default-checkbox'}
                                    label={'Спецификация с печатью'}
                                />
                                </Form>
                                <Button style={{marginRight:10}} onClick={()=>{
                                var chat_id = -575610433; // replace with yours
                                var enc_data = "This is my default text";
                                var token = "1931350754:AAGDXU8EUqiKSxaMpciJiRUY7lh4V0yfYWs"; // from botfather
                                var formData = new FormData();
                                formData.append('chat_id', chat_id);
                                formData.append('document', instance.blob, 'Спецификация.pdf');
                                var request = new XMLHttpRequest();
                                request.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                request.send(formData);
                                }}>Отправить спецификаю в Telegram</Button>

                             </div> */}


                            


                             <div>
                             <Alert show={show} variant="success">
                                <Alert.Heading>Отправлено на производство.</Alert.Heading>
                                <p>
                                
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                <Button onClick={() => setShow(false)} variant="outline-success">
                                    Закрыть
                                </Button>
                                </div>
                            </Alert>

                            {/* {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>} */}
                                <Form>
                                <Form.Check 
                                    onChange={()=>{
                                        setCheck(!check)
                                        console.log(check)
                                    }}
                                    type={'checkbox'}
                                    id={'default-checkbox'}
                                    label={'Документы с печатью'}
                                />
                                </Form>
                                <Button style={{marginRight:10}} onClick={()=>{
                                var chat_id = -575610433; // replace with yours
                                var enc_data = "This is my default text";
                                var token = "1931350754:AAGDXU8EUqiKSxaMpciJiRUY7lh4V0yfYWs"; // from botfather
                                var formData1 = new FormData();
                                var formData2 = new FormData();
                                var formData3 = new FormData();
                                var formData4 = new FormData();
                                var formData5 = new FormData();


                                formData1.append('chat_id', chat_id);
                                formData1.append('document', instance.blob, 'Спецификация.pdf');

                                formData2.append('chat_id', chat_id);
                                formData2.append('document', instance2.blob, 'Акт.pdf');
                                
                                formData3.append('chat_id', chat_id);
                                formData3.append('document', instance3.blob, 'Акт 2.pdf');

                                formData4.append('chat_id', chat_id);
                                formData4.append('document', instance4.blob, 'Акт 3.pdf');

                                formData5.append('chat_id', chat_id);
                                formData5.append('document', selectedFile);


                                


                                var  request1= new XMLHttpRequest();
                                var  request2 = new XMLHttpRequest();
                                var  request3 = new XMLHttpRequest();
                                var  request4 = new XMLHttpRequest();
                                var  request5 = new XMLHttpRequest();


                                


                                request1.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                request1.onreadystatechange = function () {
                                    if(request1.readyState === XMLHttpRequest.DONE) {
                                      var status = request1.status;
                                      if (status === 0 || (status >= 200 && status < 400)) {
                                        console.log(request1.responseText);


                                        request2.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);

                                        request2.onreadystatechange = function () {
                                            if(request2.readyState === XMLHttpRequest.DONE) {
                                              var status = request2.status;
                                              if (status === 0 || (status >= 200 && status < 400)) {
                                                console.log(request2.responseText)


                                                request3.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);

                                                request3.onreadystatechange = function () {
                                                    if(request3.readyState === XMLHttpRequest.DONE) {
                                                    var status = request3.status;
                                                    if (status === 0 || (status >= 200 && status < 400)) {
                                                        console.log(request3.responseText)
                                                        request4.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                                        request4.onreadystatechange = function () {
                                                            if(request4.readyState === XMLHttpRequest.DONE) {
                                                            var status = request4.status;
                                                            if (status === 0 || (status >= 200 && status < 400)) {
                                                                console.log(request4.responseText)
                                                                
                                                                request5.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                                                request5.onreadystatechange = function () {
                                                                    if(request5.readyState === XMLHttpRequest.DONE) {
                                                                    var status = request5.status;
                                                                    if (status === 0 || (status >= 200 && status < 400)) {
                                                                        console.log(request5.responseText)
                                                                    
                                                                    
                                                                    
                                                                    }}}

                                                                     request5.send(formData5);
                                                                    setShow(true)

                                                            }
                                                            }
                                                        }
                                                        
                                                        request4.send(formData4);
                                                    }
                                                    }
                                                }
                                                request3.send(formData3);
                                              }
                                            }
                                        }
                                        request2.send(formData2);
                                      }
                                    }
                                };
                                request1.send(formData1);

                                // request2.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                // request2.send(formData2);

                                // request3.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                // request3.send(formData3);

                                // request4.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                                // request4.send(formData4);





                                }}>Отправить документы в Telegram</Button>


                                <Form.Group style={{marginTop:10}} controlId="formFile" className="mb-3">
                                    <Form.Label>Выберите файл</Form.Label>
                                    <Form.Control type="file" onChange={changeHandler} />
                                </Form.Group>



                                </div>

                                

                                

                                
                                



                            {/* <div>
                            <Form>
                            <Form.Check 
                                onChange={()=>{
                                    setCheck2(!check2)
                                    console.log(check2)
                                }}
                                type={'checkbox'}
                                id={'default-checkbox'}
                                label={'Акт с печатью'}
                            />
                            </Form>

                            <Button style={{marginRight:10}} onClick={()=>{
                            var chat_id = -575610433; // replace with yours
                            // var enc_data = "This is my default text";
                            var token = "1931350754:AAGDXU8EUqiKSxaMpciJiRUY7lh4V0yfYWs"; // from botfather
                            var formData = new FormData();
                            formData.append('chat_id', chat_id);
                            formData.append('document', instance2.blob, 'Акт.pdf');
                            var request = new XMLHttpRequest();
                            request.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                            request.send(formData);
                            }}>Отправить акт в Telegram</Button>


                            

                            </div> */}


                            {/* <div>
                            <Form>
                            <Form.Check 
                                onChange={()=>{
                                    setCheck3(!check3)
                                    console.log(check3)
                                }}
                                type={'checkbox'}
                                id={'default-checkbox'}
                                label={'Акт2 с печатью'}
                            />
                            </Form>

                            <Button style={{marginRight:10}} onClick={()=>{
                            var chat_id = -575610433; // replace with yours
                            var enc_data = "This is my default text";
                            var token = "1931350754:AAGDXU8EUqiKSxaMpciJiRUY7lh4V0yfYWs"; // from botfather
                            var formData = new FormData();
                            formData.append('chat_id', chat_id);
                            formData.append('document', instance3.blob, 'Акт2.pdf');
                            var request = new XMLHttpRequest();
                            request.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                            request.send(formData);
                            }}>Отправить акт2 в Telegram</Button>


                            </div>


                            <div>
                            <Form>
                            <Form.Check 
                                onChange={()=>{
                                    setCheck4(!check4)
                                    console.log(check4)
                                }}
                                type={'checkbox'}
                                id={'default-checkbox'}
                                label={'Акт3 с печатью'}
                            />
                            </Form>

                            <Button onClick={()=>{
                            var chat_id = -575610433; // replace with yours
                            var enc_data = "This is my default text";
                            var token = "1931350754:AAGDXU8EUqiKSxaMpciJiRUY7lh4V0yfYWs"; // from botfather
                            var formData = new FormData();
                            formData.append('chat_id', chat_id);
                            formData.append('document', instance4.blob, 'Акт3.pdf');
                            var request = new XMLHttpRequest();
                            request.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
                            request.send(formData);
                            }}>Отправить акт3 в Telegram</Button>


                            </div> */}
                        </div>
                    </div>
            </div>   
        </div>
    )
}

export default Data
